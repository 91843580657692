.btn {
    // scss-docs-start btn-css-vars
    --#{$prefix}btn-padding-x: #{$btn-padding-x};
    --#{$prefix}btn-padding-y: #{$btn-padding-y};
    --#{$prefix}btn-font-family: #{$btn-font-family};
    @include rfs($btn-font-size, --#{$prefix}btn-font-size);
    --#{$prefix}btn-font-weight: #{$btn-font-weight};
    --#{$prefix}btn-line-height: #{$btn-line-height};
    --#{$prefix}btn-color: #{$btn-color};
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-width: #{$btn-border-width};
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-border-radius: #{$btn-border-radius};
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
    --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
    --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
    // scss-docs-end btn-css-vars

    display: block;
    margin: 0 auto;
    padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
    font-family: var(--#{$prefix}btn-font-family);
    @include font-size(var(--#{$prefix}btn-font-size));
    font-weight: var(--#{$prefix}btn-font-weight);
    line-height: var(--#{$prefix}btn-line-height);
    color: var(--#{$prefix}btn-color);
    text-align: center;
    -webkit-text-decoration: if($link-decoration ==none, null, none);
    text-decoration: if($link-decoration ==none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: if($enable-button-pointers, pointer, null);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);
    @include border-radius(var(--#{$prefix}btn-border-radius));
    @include gradient-bg(var(--#{$prefix}btn-bg));
    @include box-shadow(var(--#{$prefix}btn-box-shadow));
    @include transition($btn-transition);

    &:hover {
        color: var(--#{$prefix}btn-hover-color);
        -webkit-text-decoration: if($link-hover-decoration ==underline, none, null);
        text-decoration: if($link-hover-decoration ==underline, none, null);
        background-color: var(--#{$prefix}btn-hover-bg);
        border-color: var(--#{$prefix}btn-hover-border-color);
    }

    .btn-check+&:hover {
        // override for the checkbox/radio buttons
        color: var(--#{$prefix}btn-color);
        background-color: var(--#{$prefix}btn-bg);
        border-color: var(--#{$prefix}btn-border-color);
    }

    &:focus-visible {
        color: var(--#{$prefix}btn-hover-color);
        @include gradient-bg(var(--#{$prefix}btn-hover-bg));
        border-color: var(--#{$prefix}btn-hover-border-color);
        outline: 0;

        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: var(--#{$prefix}btn-box-shadow),
            var(--#{$prefix}btn-focus-box-shadow);
        }

        @else {
            box-shadow: var(--#{$prefix}btn-focus-box-shadow);
        }
    }

    .btn-check:focus-visible+& {
        border-color: var(--#{$prefix}btn-hover-border-color);
        outline: 0;

        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: var(--#{$prefix}btn-box-shadow),
            var(--#{$prefix}btn-focus-box-shadow);
        }

        @else {
            box-shadow: var(--#{$prefix}btn-focus-box-shadow);
        }
    }

    .btn-check:checked+&,
    :not(.btn-check)+&:active,
    &:first-child:active,
    &.active,
    &.show {
        color: var(--#{$prefix}btn-active-color);
        background-color: var(--#{$prefix}btn-active-bg);
        // Remove CSS gradients if they're enabled
        background-image: if($enable-gradients, none, null);
        border-color: var(--#{$prefix}btn-active-border-color);
        @include box-shadow(var(--#{$prefix}btn-active-shadow));

        &:focus-visible {

            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows {
                box-shadow: var(--#{$prefix}btn-active-shadow),
                var(--#{$prefix}btn-focus-box-shadow);
            }

            @else {
                box-shadow: var(--#{$prefix}btn-focus-box-shadow);
            }
        }
    }

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        color: var(--#{$prefix}btn-disabled-color);
        pointer-events: none;
        background-color: var(--#{$prefix}btn-disabled-bg);
        background-image: if($enable-gradients, none, null);
        border-color: var(--#{$prefix}btn-disabled-border-color);
        opacity: var(--#{$prefix}btn-disabled-opacity);
        @include box-shadow(none);
    }
}

.flex {
    &-line {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 1rem;
    }

    &-center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.rdu {
    font-size: $font-resize-rdu !important;
}

.expn {
    font-size: $font-resize-expn !important;

    @include media-breakpoint-up(md) {
        font-size: $font-resize-expn * 1.2;
    }
}

.text-center {
    text-align: center !important;
}

.small {
    font-size: $small-font-size !important;
}

.img-fluid {
    @include img-fluid();
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.w-100 {
    width: 100% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-1 {
    border-radius: $card-border-radius;
}

.card-text:last-child {
    margin-bottom: 0;
}