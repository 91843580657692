//-----------------------------------------------------
// ローディング #Loading
//-----------------------------------------------------
// Loading背景画面設定
#splash {
  // fixedで全面に固定
  position: fixed;
  width: 100%;
  height: 100%;
  background: $warning;
  color: #fff;
  text-align: center;
  z-index: 9999;
}

// Loadingバー中央配置
#splash_text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  color: #fff;
  transform: translate(-50%, -50%);
  z-index: 9999;
}